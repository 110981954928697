<script setup lang="ts">
import { ref } from 'vue';
import { useBkIntegratorStore } from '../../store/bk.integrator';
import { definePageMeta } from '#imports';
import BKIntegrationActionButtons from '~/features/bk/components/BKIntegrationActionButtons/BKIntegrationActionButtons.vue';
import BKIntegrationDemoBlock from '~/features/bk/components/BKIntegrationDemoBlock/BKIntegrationDemoBlock.vue';
import IFrameCaseOpenController from '~/features/bk/controllers/IFrameCaseOpenController/IFrameCaseOpenController.vue';
import BackgroundDropController from '~/controllers/BackgroundDropController/BackgroundDropController.vue';
import ItemChangeController from '~/features/bk/controllers/ItemChangeController/ItemChangeController.vue';
import IFrameSlider from '~/features/bk/components/IFrameSlider/IFrameSlider.vue';
import { useLocalIFrameStore } from '~/features/bk/store/localIFrame';
import { useDemoStore } from '~/features/bk/store/demo';
import BKIntegrationDemoPopup from '~/features/bk/components/BKIntegrationDemoPopup/BKIntegrationDemoPopup.vue';
import { ComponentNames } from '~/features/bk/constants/index.components';
import { useBkCasesStore } from '~/features/bk/store/bk.cases';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import { actionButtons } from '~/features/bk/controllers/IFrameController/IFrameController.data';

definePageMeta({
  layout: false,
});
// @ts-expect-error TODO remove or refactor
const {
  t,
  // @ts-expect-error TODO remove or refactor
  setLocale,
  locales,
  locale,
}: {
  locale: object;
  locales: object[];
  // @ts-expect-error TODO remove or refactor
  setLocal: (key) => void;
  t: (key: string) => string;
} = useI18n();

const isLoading = ref(false);

const bkModalStore = useBkModalStore();
const { isModalOpened, modalComponent } = storeToRefs(bkModalStore);

const localStore = useLocalIFrameStore();
const store = useBkCasesStore();
const demoStore = useDemoStore();
const integratorBk = useBkIntegratorStore();
const { popups } = storeToRefs(localStore);
const { caseBg } = storeToRefs(store);
const { urlOptions } = storeToRefs(integratorBk);

// TODO переключить после установки API
await store.getCase(urlOptions.value.caseName);
</script>

<template>
  <div class="wrapper">
    <!-- <transition name="fade">
      <BKIntegrationLoader v-if="isLoading" loader-position="relative" @animationend="isLoading = false" />
    </transition> -->
    <transition name="fade">
      <div v-show="!isLoading">
        <SharedButton @click="bkModalStore.setCurrentComponent(ComponentNames.ERROR)"
          >Модальное окно ошибка
        </SharedButton>
        <SharedButton @click="bkModalStore.setCurrentComponent(ComponentNames.ERROR_BALANCE)"
          >Модальное окно Недостаточно средств
        </SharedButton>
        <SharedButton @click="bkModalStore.setCurrentComponent(ComponentNames.DENY_EXCHANGE)"
          >Модальное окно Невозможно вывести предмет
        </SharedButton>
        <!-- @vue-skip -->
        <select
          @change="
            ({ target: { value } }) => {
              setLocale(value);
            }
          "
        >
          <option>Выбрать язык</option>
          <option
            v-for="lang of locales"
            :key="lang.code"
            :value="lang.code"
            :style="{
              background: lang.code === locale ? 'lightgrey' : null,
            }"
          >
            {{ lang.name }}
          </option>
        </select>
        <BackgroundDropController v-show="!isLoading" :case-bg="caseBg" type="full" blur>
          <SharedModal v-model="isModalOpened">
            <component :is="modalComponent" />
          </SharedModal>
          <BKIntegrationDemoBlock v-if="demoStore.isDemo" />
          <IFrameSlider v-if="popups.gameInfo" v-model:show-slider="popups.gameInfo" />
          <!-- @vue-skip -->
          <ItemChangeController v-model="popups.change" />

          <IFrameCaseOpenController />
          <!-- @vue-skip -->
          <BKIntegrationDemoPopup v-model="localStore.popups.demoGetItems" />

          <BKIntegrationActionButtons :buttons="actionButtons(bkModalStore, t)" />
        </BackgroundDropController>
      </div>
    </transition>
  </div>
</template>

<style src="./IFrameTestController.scss" scoped lang="scss"></style>
